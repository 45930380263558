import React from 'react';
import {
  Box,
  Flex,
  PrimaryHeading,
  PrimaryText,
  spacing,
  useColorMode,
} from 'deepstash-ui';
import { MailSvg, PersonSvg, SettingsSvg, color } from 'deepstash-ui';
import { SettingsType } from './SettingsPage';
import BaseCard from 'src/components/cards/BaseCard';

interface OwnProps {
  title: string;
  description: string;
  value: SettingsType;
  selectTab: () => void;
}

const SettingsCard: React.FunctionComponent<OwnProps> = ({
  title,
  description,
  selectTab,
  value,
}) => {
  const { colorMode } = useColorMode();

  const renderIcon = () => {
    const boxSize = spacing.toRem(40);
    switch (value) {
      case SettingsType.Emails:
        return <MailSvg color={color[colorMode].text} boxSize={boxSize} />;
      case SettingsType.PersonalInformation:
        return <PersonSvg color={color[colorMode].text} boxSize={boxSize} />;
      case SettingsType.Preferences:
        return <SettingsSvg color={color[colorMode].text} boxSize={boxSize} />;
      default:
        return <Box />;
    }
  };

  return (
    <BaseCard
      flexDirection="column"
      onClick={() => selectTab()}
      p={spacing.toRem(32)}
      pt={spacing.toRem(36)}
      _hover={{ cursor: 'pointer' }}
      hasHoverEffect
    >
      <Box>{renderIcon()}</Box>
      <Flex alignContent="center" pt={spacing.toRem(8)}>
        <PrimaryHeading size="h1" type="bold" color={color[colorMode].text}>
          {title}
        </PrimaryHeading>
      </Flex>
      <PrimaryText color={color[colorMode].textSecondary}>
        {description}
      </PrimaryText>
    </BaseCard>
  );
};

export default SettingsCard;
