import { postExportLibrary } from 'api/Profile';
import {
  Box,
  Flex,
  FlexProps,
  PrimaryHeading,
  PrimaryText,
  color,
  spacing,
  useColorMode,
  useToast,
} from 'deepstash-ui';
import useProfile from 'providers/hooks/useProfile';
import React, { useCallback } from 'react';
import { ExportSvg } from 'theme/icons/ExportSvg';
import BaseCard from '../cards/BaseCard';
import { TOAST_TIME } from 'utils/constants';

interface ExportLibraryButtonProps {}

const ExportLibraryButton: React.FC<ExportLibraryButtonProps & FlexProps> = ({
  ...props
}) => {
  const { colorMode } = useColorMode();
  const { profile } = useProfile();
  const toast = useToast();

  const onClickHandler = useCallback(async () => {
    postExportLibrary()
      .then(() => {
        toast({
          title: 'Export initiated!',
          description: 'Please check your email soon.',
          status: 'success',
          duration: TOAST_TIME,
          isClosable: true,
        });
        return;
      })
      .catch(() => {
        toast({
          title: 'Export in progress!',
          description:
            'Another export has been already initiated. Wait for it to finish before starting a new one.',
          status: 'warning',
          duration: TOAST_TIME,
          isClosable: true,
        });
        return;
      });
  }, [profile?.id]);

  return (
    <BaseCard
      flexDirection="column"
      p={spacing.toRem(32)}
      pt={spacing.toRem(36)}
      _hover={{ cursor: 'pointer' }}
      onClick={onClickHandler}
      hasHoverEffect
      {...props}
    >
      <Box>
        <ExportSvg color={color[colorMode].text} boxSize={spacing.toRem(40)} />
      </Box>
      <Flex alignContent="center" pt={spacing.toRem(8)}>
        <PrimaryHeading size="h1" type="bold" color={color[colorMode].text}>
          Export Library
        </PrimaryHeading>
      </Flex>
      <PrimaryText color={color[colorMode].textSecondary}>
        Export your saved ideas via email.
      </PrimaryText>
    </BaseCard>
  );
};

export default ExportLibraryButton;
