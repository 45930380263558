import {
  ArticleApiResponse,
  BookApiResponse,
  InfluencerApiResponse,
  InsightApiResponse,
  ProfileApiResponse,
  PublisherBookApiResponse,
  StashApiResponse,
  StoryApiResponse,
} from 'api/api.types';
import { MENU_OPTIONS } from 'pages/search';
import { jsonLdScriptProps } from 'react-schemaorg/dist/src/json-ld';
import {
  AboutPage,
  Article,
  Book,
  Collection,
  Organization,
  ProfilePage,
  PropertyValueSpecification,
  SearchAction,
  WebSite,
  Person,
} from 'schema-dts';
import { getRandomBookRatingById } from 'src/page-components/books/MasterBookPage';
import { SourceType } from 'types/enums';
import { PageMetadata } from 'types/metadataTypes';
import { CollectionData } from 'types/models';
import { cleanInsightTitle, getSourceTypeTitle, slugify } from './global';
import commonStrings from './strings/commonStrings';
import pageStrings from './strings/pageStrings';

type PageMetadataGetter<T = void> = (data: T) => PageMetadata;

/**
 * Used for the search bar on the homepage
 */
type ExtendedSearchAction = SearchAction & {
  'query-input': PropertyValueSpecification | string;
};

const DEFAULT_PUBLIC_PAGE_OG_TAGS = {
  title: 'Deepstash - Replace doomscrolling with microlearning!',
  type: 'website',
  image: 'https://static.deepstash.com/deepstash_web_share_cover.jpg',
  description:
    'Stop doomscrolling. Start microlearning. Join 3M+ smart people like you.',
};

/**
 * @link /[hashTag]
 * @returns {PageMetadata} opengraph metadata
 */
export const getTopicPageMetadata: PageMetadataGetter<{
  topicTitle: string;
  topicName: string;
  exampleArticleTitle?: string;
  pageCount?: string;
  pageTitle?: string;
  pageDescription?: string;
}> = ({
  topicName,
  topicTitle,
  exampleArticleTitle,
  pageCount,
  pageTitle: pageTitleFromProps,
  pageDescription: pageDescriptionFromProps,
}) => {
  // Discover amazing ideas on #{topic name} like {article name} or many others.

  const pageDescription =
    pageDescriptionFromProps ??
    `Discover amazing ideas on ${topicTitle}` +
      (!!exampleArticleTitle && exampleArticleTitle !== ''
        ? ` like ${exampleArticleTitle} or many others.`
        : '.');

  const topicPageMetadata: PageMetadata = {
    pageTitle: pageTitleFromProps ?? topicTitle + ' - Deepstash',
    pageDescription: pageDescription,
    canonical: `https://deepstash.com/topic/${topicName}${
      pageCount ? `/${pageCount}` : ''
    }`,
    robotsTag: 'index',
    openGraphTags: {
      type: 'topic',
      description: `Get the best ideas on ${topicTitle}`,
      title: topicTitle,
    },
    twitterTags: {
      card: 'summary_large_image',
    },
  };
  return topicPageMetadata;
};

/**
 * @link /book/[id]/[slug], 301 redirects: /book/[id], /book/[id]/[wrong-slug]
 * @returns {PageMetadata} jsonld + opengraph metadata
 */
export const getBookPageMetadata: PageMetadataGetter<{
  book: BookApiResponse;
}> = ({ book }) => {
  const authorNameJoin = book.publishers
    .map(publisher => publisher.name)
    .join(', ');

  const bookPageDescription = `Read the book summary of ${book.title} by ${authorNameJoin} in 5m or less. Browse hundreds of key ideas from several different book summaries by Deepstash users.`;

  const canonicalUrl = `https://deepstash.com/book/${book.id}/${slugify(
    book.title,
  )}`;

  const ratingCount = book.identifier.slice(-4);
  const randomBookRating = Number(getRandomBookRatingById(book.id));

  const bookPageMetadata: PageMetadata = {
    pageTitle: book.title + ' Summary 2024',
    pageDescription: bookPageDescription,
    canonical: canonicalUrl,
    robotsTag: 'index',
    openGraphTags: {
      title: book.title,
      description: bookPageDescription,
      type: 'book',
      image: book.image ?? undefined,
    },
    twitterTags: {
      card: 'summary_large_image',
    },
    authorTag: book.publishers?.join(', ') ?? undefined,

    structuredDataObjects: [
      jsonLdScriptProps<Book>({
        '@context': 'https://schema.org',
        '@type': 'Book',
        '@id': canonicalUrl,
        mainEntityOfPage: {
          '@type': 'Book',
          '@id': canonicalUrl,
        },
        // add the image field only if it's available
        ...(book.image && {
          image: book.image,
        }),
        dateModified: book.created_at,
        name: book.title,
        sameAs: canonicalUrl,
        author: {
          '@type': 'Person',
          name: authorNameJoin,
        },
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: randomBookRating,
          bestRating: 5,
          ratingCount: Number.parseInt(ratingCount),
        },
      }),
    ],
  };
  return bookPageMetadata;
};

/**
 * @link /book/author/[id]/[slug], 301 redirects: /book/author/[id], /book/author/[id]/[wrong-slug]
 * @returns {PageMetadata} jsonld + opengraph metadata
 */
export const getBookAuthorPageMetadata: PageMetadataGetter<{
  publisher: PublisherBookApiResponse;
}> = ({ publisher }) => {
  const publisherDescription =
    publisher.top_sources !== undefined &&
    publisher.top_sources[0] !== undefined
      ? `Discover all ${publisher.name} books and read top summaries & key ideas from ${publisher.top_sources[0]?.title} and more.`
      : `Discover all ${publisher.name} books and read top summaries & key ideas.`;

  const canonicalUrl = `https://deepstash.com/book/author/${
    publisher.id
  }/${slugify(publisher.name)}`;

  const bookAuthorPageMetadata: PageMetadata = {
    pageTitle: `${publisher.name}: Books & Author Bio 2024`,
    pageDescription: publisherDescription,
    canonical: canonicalUrl,
    robotsTag: 'index',
    openGraphTags: {
      title: publisher.name,
      description: publisherDescription,
      type: 'person',
      image: publisher.image ?? undefined,
    },
    twitterTags: {
      card: 'summary_large_image',
    },
    authorTag: publisher.name,
    structuredDataObjects: [
      jsonLdScriptProps<Person>({
        '@context': 'https://schema.org',
        '@type': 'Person',
        '@id': canonicalUrl,
        // add the image field only if it's available
        ...(publisher.image && {
          image: publisher.image,
        }),
        name: publisher.name,
        sameAs: canonicalUrl,
      }),
    ],
  };
  return bookAuthorPageMetadata;
};

/**
 * @link /article/[id]/[slug], 301 redirects: /article/[id], /article/[id]/[wrong-slug]
 * @returns {PageMetadata} jsonld + opengraph metadata
 */
export const getArticlePageMetadata: PageMetadataGetter<{
  article: ArticleApiResponse;
}> = ({ article }) => {
  const { author, id, title, blocks, source_type, topics, image_preview } =
    article;
  let articlePageDescriptionTopicsPrefix: string = topics ? ' on ' : '';

  topics?.slice(topics.length - 1).forEach((topic, index) => {
    articlePageDescriptionTopicsPrefix += `#${topic.name}`;
    if (index < topics.length - 1) {
      articlePageDescriptionTopicsPrefix += ', ';
    } else {
      articlePageDescriptionTopicsPrefix += ' and ';
    }
  });
  articlePageDescriptionTopicsPrefix += topics
    ? topics[topics.length - 1].name
    : '';

  const articlePageDescription =
    `Learn something new today. ` +
    `Discover ${blocks ? blocks.length + ' ' : ''}` +
    `idea${blocks && blocks.length === 1 ? '' : 's'} ` +
    `from this ${getSourceTypeTitle(source_type)}` +
    articlePageDescriptionTopicsPrefix;

  const canonicalUrl = `https://deepstash.com/article/${id}/${slugify(title)}`;

  const articlePageMetadata: PageMetadata = {
    pageTitle: article.title ?? '',
    pageDescription: articlePageDescription,
    canonical: canonicalUrl,
    robotsTag: 'index',
    openGraphTags: {
      title,
      description: articlePageDescription,
      type: source_type === SourceType.BOOK ? 'book' : 'article',
      image: image_preview ?? undefined,
    },
    twitterTags: {
      card: 'summary_large_image',
    },
    authorTag: author,
    structuredDataObjects: [
      jsonLdScriptProps<Article | Book>({
        '@context': 'https://schema.org',
        '@type': source_type === SourceType.BOOK ? 'Book' : 'Article',
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': canonicalUrl,
        },
        // add the image field only if it's available
        ...(article.image_compressed && {
          image: article.image_compressed,
        }),
        ...(article.image && {
          image: article.image,
        }),
        ...(article.image_preview && {
          image: article.image_preview,
        }),
        dateModified: article.updated_at,
        name: article.title,
        sameAs: canonicalUrl,
        author: {
          '@type': 'Person',
          name:
            article.user_data.first_name + ' ' + article.user_data.last_name,
          url: `https://deepstash.com/u/${article.user_data.username}`,
        },
        isAccessibleForFree: true,
        hasPart: {
          '@type': 'WebPageElement',
          isAccessibleForFree: false,
          cssSelector: '.paywall',
        },
      }),
    ],
  };
  return articlePageMetadata;
};

/**
 * @link /u/[username]
 * @returns {PageMetadata} jsonld + opengraph
 */
export const getProfilePageMetadata: PageMetadataGetter<{
  user: ProfileApiResponse;
}> = ({ user }) => {
  const name =
    user.first_name || user.last_name
      ? `${user.first_name} ${user.last_name}`
      : user.username;

  const usernamePageMetadata: PageMetadata = {
    pageTitle: `${name} (@${user.username})`,
    pageDescription: `Find the latest ideas from ${name} (@${user.username}). ${
      user.bio ?? ''
    }`,
    canonical: `https://deepstash.com/u/${user.username}`,
    robotsTag: user.num_published_articles >= 2 ? 'index' : 'noindex, nofollow',
    openGraphTags: {
      title: user.username,
      image: user.image_preview ?? user.photo,
      description: `Find the latest ideas from ${name} (@${user.username}). ${
        user.bio ?? ''
      }`,
      type: 'profile',
    },
    twitterTags: {
      card: 'summary_large_image',
    },
    structuredDataObjects: [
      jsonLdScriptProps<ProfilePage>({
        '@context': 'https://schema.org',
        '@type': 'ProfilePage',
        mainEntity: {
          '@type': 'Person',
          image: user.image_preview ?? user.photo,
          name: name,
          sameAs: user.twitter_username
            ? [
                `https://deepstash.com/u/${user.username}`,
                `https://twitter.com/${user.twitter_username}`,
              ]
            : `https://deepstash.com/u/${user.username}`,
        },
        mainEntityOfPage: `https://deepstash.com/u/${user.username}`,
        description: user.bio ?? '',
      }),
    ],
  };
  return usernamePageMetadata;
};

/**
 * @link /stash/[id]/[slug], 301 redirects: /stash/[id], /stash/[id]/[wrong-slug]
 * @param {StashApiResponse} stash
 * @returns {PageMetadata} opengraph metadata
 */
export const getStashPageMetadata: PageMetadataGetter<{
  stash: StashApiResponse;
}> = ({ stash }) => {
  const stashPageMetadata: PageMetadata = {
    pageTitle: stash.name,
    pageDescription: `Get ${stash.insights_count} amazing ideas on ${stash.name}.`,
    canonical: `https://deepstash.com/stash/${stash.id}/${slugify(stash.name)}`,
    robotsTag: 'noindex',
    openGraphTags: {
      type: 'stash',
      title: stash.name,
      image: stash.image_preview ?? stash.image,
      description: `Get ${stash.insights_count} amazing ideas on ${stash.name}.`,
    },
    twitterTags: {
      card: 'summary_large_image',
    },
    authorTag: stash.user_data?.username,
  };

  return stashPageMetadata;
};

/**
 * @link /idea/[id]/[slug], 301 redirects: /idea/[id], /idea/[id]/[wrong-slug]
 * @returns {PageMetadata} opengraph metadata
 */
export const getIdeaPageMetadata: PageMetadataGetter<{
  insight: InsightApiResponse;
}> = ({ insight }) => {
  const insightPageTitle = insight.author_name
    ? `Quote by ${insight.author_name}`
    : `${cleanInsightTitle(insight)}`;
  const insightPageDescription = insight.content
    .replace(/<\/?\s*[a-z]+[^>]*>/gi, '')
    .split(' ')
    .splice(0, 30)
    .join(' ')
    .concat('...');

  const ideaPageMetadata: PageMetadata = {
    pageTitle: insightPageTitle,
    pageDescription: insightPageDescription,
    canonical: `https://deepstash.com/idea/${insight.id}/${slugify(
      insight.title,
      insight.content,
    )}`,
    robotsTag: 'index',
    openGraphTags: {
      type: 'idea',
      title: insightPageTitle,
      description: insightPageDescription,
      image: insight.image_preview,
    },
    twitterTags: {
      card: 'summary_large_image',
    },
    authorTag: insight.author_name ?? undefined,
  };
  return ideaPageMetadata;
};

/**
 * @link /mobile-app
 * @returns {PageMetadata} basic metadata
 */
export const getMobileAppPageMetadata: PageMetadataGetter = () => {
  const mobileAppPageMetadata: PageMetadata = {
    pageTitle: 'Get the app',
    pageDescription: pageStrings.about.pageDescription,
    canonical: 'https://deepstash.com/mobile-app',
    robotsTag: 'index',
    openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
  };
  return mobileAppPageMetadata;
};

/**
 * @link /curators
 * @returns {PageMetadata} basic metadata
 */
export const getCuratorsPageMetadata: PageMetadataGetter = () => {
  const curatorsMetadata: PageMetadata = {
    pageTitle: 'Curators',
    pageDescription:
      'You are your thoughts. It’s our goal to help you become more inspired, wiser and productive, through bite-sized ideas.',
    canonical: 'https://deepstash.com/curators',
    robotsTag: 'index',
    openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
  };
  return curatorsMetadata;
};

/**
 * @link /email_unsubscribe
 * @returns {PageMetadata} basic metadata
 */
export const getEmailUnsubscribePageMetadata: PageMetadataGetter = () => {
  const metadata: PageMetadata = {
    pageTitle: 'Email Unsubscribe',
    pageDescription: '',
    canonical: 'https://deepstash.com/email_unsubscribe',
    robotsTag: 'index',
  };
  return metadata;
};

/**
 * @link /about
 * @returns {PageMetadata} jsonld metadata
 */
export const getAboutPageMetadata: PageMetadataGetter = () => {
  const aboutPageMetadata: PageMetadata = {
    pageTitle: 'About',
    pageDescription: pageStrings.about.pageDescription,
    canonical: 'https://deepstash.com/about',
    robotsTag: 'index',
    openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
    structuredDataObjects: [
      jsonLdScriptProps<AboutPage>({
        '@context': 'https://schema.org',
        '@type': 'AboutPage',
        description: pageStrings.about.pageDescription,
        image: 'https://static.deepstash.com/deepstash-logo.png',
        name: 'Deepstash',
        url: 'https://deepstash.com',
        version: `${process.env.WEBSITE_VERSION}`,
      }),
    ],
  };
  return aboutPageMetadata;
};

/**
 * @link /featured
 * @return {PageMetadata} basic metadata
 */
export const getFeaturedPageMetadata: PageMetadataGetter = () => {
  const featuredMetadata: PageMetadata = {
    pageTitle: 'Featured Articles',
    pageDescription:
      'Discover featured stashes, articles and ideas happening right now. Don’t miss out on our team’s picks for today.',
    canonical: 'https://deepstash.com/featured',
    robotsTag: 'index',
    openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
  };
  return featuredMetadata;
};

/**
 * @link /notifications
 * @return {PageMetadata} metadata
 */
export const getNotificationsPageMetadata: PageMetadataGetter = () => {
  const metadata: PageMetadata = {
    pageTitle: 'Notifications',
    pageDescription: 'See your notifications',
    canonical: 'https://deepstash.com/notifications',
    robotsTag: 'index',
  };
  return metadata;
};

/**
 * @link /password_reset
 * @return {PageMetadata} metadata
 */
export const getPasswordResetPageMetadata: PageMetadataGetter = () => {
  const pageMetadata: PageMetadata = {
    pageTitle: 'Reset Password',
    pageDescription: 'Reset your password',
    canonical: 'https://deepstash.com/password_reset',
    robotsTag: 'index',
  };
  return pageMetadata;
};

/**
 * @link /press-kit
 * @return {PageMetadata} metadata
 */
export const getPressKitPageMetadata: PageMetadataGetter = () => {
  const pressKitMetadata: PageMetadata = {
    pageTitle: 'Press Kit',
    pageDescription:
      'You are your thoughts. It’s our goal to help you become more inspired, wiser and productive, through bite-sized ideas.',
    canonical: 'https://deepstash.com/press-kit',
    robotsTag: 'index',
    openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
  };
  return pressKitMetadata;
};

/**
 * @link /privacy-policy
 * @return {PageMetadata} metadata
 */
export const getPrivacyPolicyMetadata: PageMetadataGetter = () => {
  const pageMetadata: PageMetadata = {
    pageTitle: 'Privacy Policy',
    pageDescription:
      'Deepstash takes your data privacy seriously. Our privacy policy entails who we are, how we collect, share and use your personal information.',
    canonical: 'https://deepstash.com/privacy-policy',
    robotsTag: 'index',
    openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
  };
  return pageMetadata;
};

/**
 * @link /progress
 * @return {PageMetadata} metadata
 */
export const getProgressPageMetadata: PageMetadataGetter = () => {
  const progressMetadata: PageMetadata = {
    pageTitle: 'Progress',
    pageDescription: 'See your progress',
    canonical: 'https://deepstash.com/progress',
    robotsTag: 'index',
  };
  return progressMetadata;
};

/**
 * @link /
 * @return {PageMetadata} metadata
 */
export const getStashingPageMetadata: PageMetadataGetter = () => {
  const stashingPageMetadata: PageMetadata = {
    pageTitle: 'The art of stashing',
    pageDescription: 'Best practices to stashing articles you come across',
    canonical: 'https://deepstash.com/stashing',
    robotsTag: 'index',
  };
  return stashingPageMetadata;
};

/**
 * @link /terms-of-service
 * @return {PageMetadata} metadata
 */
export const getTermsOfServicePageMetadata: PageMetadataGetter = () => {
  const tosPageMetadata: PageMetadata = {
    pageTitle: 'Terms of Service',
    pageDescription:
      'Please read the following terms of service before you install the Deepstash application or use any of the products or services we provide through our application, software or website.',
    canonical: 'https://deepstash.com/terms-of-service',
    robotsTag: 'index',
    openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
  };
  return tosPageMetadata;
};

/**
 * @link /trending
 * @return {PageMetadata} metadata
 */
export const getTrendingPageMetadata: PageMetadataGetter = () => {
  const trendingPageMetadata: PageMetadata = {
    pageTitle: 'Trending Articles',
    pageDescription:
      'Discover popular stashes, articles and ideas happening right now. Don’t miss out on today’s hot trends.',
    canonical: 'https://deepstash.com/trending',
    robotsTag: 'index',
    openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
  };
  return trendingPageMetadata;
};

/**
 * @link /content-review-article/[id]
 * @return {PageMetadata} metadata
 */
export const getContentReviewArticlePageMetadata: PageMetadataGetter<{
  data: ArticleApiResponse;
}> = ({ data }) => {
  const contentReviewMetadata: PageMetadata = {
    pageTitle: data.title ?? '',
    pageDescription: 'Review ' + data.title,
    canonical: 'https://deepstash.com/content-review-article/' + data.id,
    robotsTag: 'noindex',
  };
  return contentReviewMetadata;
};

/**
 * @link /content-review-articles/in-review
 * @return {PageMetadata} metadata
 */
export const getContentReviewArticlesInReviewPageMetadata: PageMetadataGetter =
  () => {
    const contentReviewMetadata: PageMetadata = {
      pageTitle: 'In Review',
      pageDescription: 'View articles in review',
      canonical: 'https://deepstash.com/content-review-articles/in-review',
      robotsTag: 'noindex',
    };
    return contentReviewMetadata;
  };

/**
 * @link /content-review-articles/all-drafts
 * @return {PageMetadata} metadata
 */
export const getContentReviewArticlesAllDraftsPageMetadata: PageMetadataGetter =
  () => {
    const contentReviewMetadata: PageMetadata = {
      pageTitle: 'Assigned Articles',
      pageDescription: 'View assigned articles',
      canonical: 'https://deepstash.com/content-review-articles/all-drafts',
      robotsTag: 'noindex',
    };
    return contentReviewMetadata;
  };

/**
 * @link /delete-account
 * @return {PageMetadata} metadata
 */
export const getDeleteAccountPageMetadata: PageMetadataGetter = () => {
  const deleteAccountPageMetadata: PageMetadata = {
    pageTitle: 'Delete Account',
    pageDescription: 'Delete your account',
    canonical: 'https://deepstash.com/settings/delete-account',
    robotsTag: 'noindex',
  };
  return deleteAccountPageMetadata;
};

/**
 * @link /email-subscription
 * @return {PageMetadata} metadata
 */
export const getEmailSubscriptionsPageMetadata: PageMetadataGetter = () => {
  const emailSubscriptionsPageMetadata: PageMetadata = {
    pageTitle: 'Email Subscriptions',
    pageDescription: 'View your email subscriptions',
    canonical: 'https://deepstash.com/settings/email-subscriptions',
    robotsTag: 'index',
  };
  return emailSubscriptionsPageMetadata;
};

/**
 * @link /personal-information
 * @return {PageMetadata} metadata
 */
export const getPersonalInformationPageMetadata: PageMetadataGetter = () => {
  const personalInformationPageMetadata: PageMetadata = {
    pageTitle: 'Personal Information',
    pageDescription: 'View your personal information',
    canonical: 'https://deepstash.com/settings/personal-information',
    robotsTag: 'index',
  };
  return personalInformationPageMetadata;
};

/**
 * @link /preferences
 * @return {PageMetadata} metadata
 */
export const getPreferencesPageMetadata: PageMetadataGetter = () => {
  const personalInformationPageMetadata: PageMetadata = {
    pageTitle: 'Preferences',
    pageDescription: 'View your preferences',
    canonical: 'https://deepstash.com/settings/preferences',
    robotsTag: 'index',
  };
  return personalInformationPageMetadata;
};

/**
 * @link /ref
 * @return {PageMetadata} metadata
 */
export const getRefPageMetadata: PageMetadataGetter = () => {
  const invitePageMetadata: PageMetadata = {
    pageTitle: 'Invite',
    pageDescription: 'Invitation to join Deepstash',
    canonical: `https://deepstash.com/ref`,
    robotsTag: 'noindex',
    openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
  };
  return invitePageMetadata;
};

/**
 * @link /settings
 * @return {PageMetadata} metadata
 */
export const getSettingsPageMetadata: PageMetadataGetter = () => {
  const settingsPageMetadata: PageMetadata = {
    pageTitle: 'Settings',
    pageDescription: 'View your personal settings',
    canonical: 'https://deepstash.com/settings',
    robotsTag: 'index',
  };
  return settingsPageMetadata;
};

/**
 * TO BE DELETED APPARENTLY?
 * @link /likes
 * @return {PageMetadata} metadata
 */
export const getLikesPageMetadata: PageMetadataGetter<{
  user: ProfileApiResponse;
}> = ({ user }) => {
  const name =
    user.first_name || user.last_name
      ? `${user.first_name} ${user.last_name}`
      : user.username;

  const likedPageMetadata: PageMetadata = {
    pageTitle: name,
    pageDescription: 'See liked posts.',
    canonical: `https://deepstash.com/${user?.username}/liked`,
    robotsTag: 'index',
  };
  return likedPageMetadata;
};

/**
 * @link /search
 * @return {PageMetadata} metadata
 */
export const getSearchPageMetadata: PageMetadataGetter<
  {
    searchText: string;
    searchType: string;
  } | void
> = searchParams => {
  const pageTitle = searchParams
    ? `${
        MENU_OPTIONS.find(option => option.key === searchParams.searchType)
          ?.title
      } ${searchParams.searchType === 'user' ? 'named' : 'related to'} '${
        searchParams.searchText
      }'`
    : 'Search';
  const searchPageMetadata: PageMetadata = {
    pageTitle: pageTitle,
    pageDescription:
      'Search for ideas, topics, articles, books, podcasts, and more...',
    canonical: 'https://deepstash.com/search',
    robotsTag: 'noindex',
    openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
  };
  return searchPageMetadata;
};

/**
 * @link /[genericTag]
 * @return {PageMetadata} metadata
 */
export const getInfluencerPageMetadata: PageMetadataGetter<{
  influencer: InfluencerApiResponse & { influencerPath: string };
}> = ({ influencer }) => {
  const influencerPageMetadata: PageMetadata = {
    pageTitle: `Start your stashing journey with ${influencer.creator_name}`,
    pageDescription: `${influencer.creator_name} (@${influencer.user.username}) invites you to join Deepstash Pro with a discount to our subscription!`,
    robotsTag: 'noindex',
    canonical: 'https://deepstash.com' + influencer.influencerPath,
    openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
  };
  return influencerPageMetadata;
};

/**
 * @link /
 * @return {PageMetadata} metadata
 */
export const getHomePageMetadata: PageMetadataGetter = () => {
  const homePageMetadata: PageMetadata = {
    pageTitle: commonStrings.homePage.pageTitle,
    openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
    pageDescription: commonStrings.homePage.pageDescription,
    canonical: 'https://deepstash.com',
    robotsTag: 'index',
    structuredDataObjects: [
      jsonLdScriptProps<Organization>({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: 'Deepstash',
        url: 'https://deepstash.com',
        logo: 'https://static.deepstash.com/deepstash.png',
        sameAs: [
          'https://www.facebook.com/deepstash/',
          'https://twitter.com/deepstash/',
          'https://www.instagram.com/deepstash/',
          'https://www.youtube.com/channel/UCXh3Zca5WIW0C_tQJrDoa1w',
          'https://www.linkedin.com/company/deepstash/',
          'https://deepstash.com',
        ],
      }),
      jsonLdScriptProps<WebSite>({
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: 'https://deepstash.com',
        potentialAction: {
          '@type': 'SearchAction',
          target: 'https://deepstash.com/search?q={search_term_string}',
          'query-input': 'required name=search_term_string',
        } as ExtendedSearchAction,
      }),
    ],
  };
  return homePageMetadata;
};

/**
 * @link /producthunt
 * @return {PageMetadata} metadata
 */
export const getProductHuntPageMetadata: PageMetadataGetter = () => {
  const productHuntPageMetadata: PageMetadata = {
    pageTitle: commonStrings.homePage.pageTitle,
    openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
    pageDescription: commonStrings.homePage.pageDescription,
    canonical: 'https://deepstash.com/producthunt',
    robotsTag: 'noindex',
    structuredDataObjects: [
      jsonLdScriptProps<Organization>({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: 'Deepstash',
        url: 'https://deepstash.com',
        logo: 'https://static.deepstash.com/deepstash.png',
        sameAs: [
          'https://www.facebook.com/deepstash/',
          'https://twitter.com/deepstash/',
          'https://www.instagram.com/deepstash/',
          'https://www.youtube.com/channel/UCXh3Zca5WIW0C_tQJrDoa1w',
          'https://www.linkedin.com/company/deepstash/',
          'https://deepstash.com',
        ],
      }),
    ],
  };
  return productHuntPageMetadata;
};

/**
 * @link /draft/[id]
 * @return {PageMetadata} metadata
 */
export const getDraftPageMetadata: PageMetadataGetter = () => {
  const addSourceMetadata: PageMetadata = {
    pageTitle: 'Add Ideas',
    pageDescription: 'Add ideas to your draft post.',
    canonical: '',
    robotsTag: 'noindex',
  };
  return addSourceMetadata;
};

/**
 * @link /404
 * @return {PageMetadata} metadata
 */
export const getErrorPageMetadata: PageMetadataGetter = () => {
  const errorPageMetadata: PageMetadata = {
    pageTitle: '404 Page not found',
    pageDescription: 'The requested page has not been found.',
    canonical: 'https://deepstash.com/404',
    robotsTag: 'noindex',
  };
  return errorPageMetadata;
};

/**
 * @link /stashes
 * @return {PageMetadata} metadata
 */
export const getStashesPageMetadata: PageMetadataGetter<{
  user: ProfileApiResponse;
}> = ({ user }) => {
  const name =
    user.first_name || user.last_name
      ? `${user.first_name} ${user.last_name}`
      : user.username;

  const stashesPageMetadata: PageMetadata = {
    pageTitle: name,
    pageDescription: 'See stashed posts.',
    canonical: `https://deepstash.com/u/${user?.username}/stashes`,
    robotsTag: 'index',
  };

  return stashesPageMetadata;
};

/**
 * @link /all-collections
 * @return {PageMetadata} metadata
 */
export const getAllCollectionsPageMetadata: PageMetadataGetter = () => {
  const allCollectionsPageMetadata: PageMetadata = {
    pageTitle: 'All Collections',
    pageDescription: 'See your collections.',
    canonical: `https://deepstash.com/all-collections`,
    robotsTag: 'noindex',
  };

  return allCollectionsPageMetadata;
};

/**
 * @link /collection/[id]/[slug], 301 redirects: /collection/[id], /collection/[id]/[wrong-slug]
 * @return {PageMetadata} jsonld + opengraph metadata
 */
export const getCollectionPageMetadata: PageMetadataGetter<{
  collection: CollectionData;
}> = ({ collection }) => {
  const collectionDescription = collection.hashtags
    ? 'Read this collection on #' + collection.hashtags[0] + ' and more.'
    : 'Read this collection of ' + collection.ideaCount + ' ideas.';

  const canonicalUrl = `https://deepstash.com/collection/${
    collection.id
  }/${slugify(collection.name)}`;

  const collectionPageMetadata: PageMetadata = {
    pageTitle: collection.name,
    pageDescription: collectionDescription,
    canonical: canonicalUrl,
    robotsTag: 'index',
    openGraphTags: {
      title: collection.name,
      description: collectionDescription,
      type: 'collection',
      image: collection.imagePreview,
    },
    twitterTags: {
      card: 'summary_large_image',
    },
    structuredDataObjects: [
      jsonLdScriptProps<Collection>({
        '@context': 'https://schema.org',
        '@type': 'Collection',
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': `https://deepstash.com/collection/${collection.id}`,
        },
        // add the image field only if it's available
        ...(collection.imageUrl && {
          image: collection.imageUrl,
        }),
        name: collection.name,
        sameAs: canonicalUrl,
        collectionSize: collection.sources?.length ?? 0,
      }),
    ],
  };
  return collectionPageMetadata;
};

/**
 * @link /for-teams
 */
export const getForTeamsPageMetadata: PageMetadataGetter = () => {
  const forTeamsPageMetadata: PageMetadata = {
    pageTitle: 'For Teams',
    pageDescription:
      'Your team is at the heart of your organization’s success. Deepstash helps yours get creative, innovative and engaged.',
    canonical: 'https://deepstash.com/for-teams',
    robotsTag: 'index',
    openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
  };
  return forTeamsPageMetadata;
};

/**
 * @link /get-pro
 */
export const getGetProPageMetadata: PageMetadataGetter = () => {
  const forTeamsPageMetadata: PageMetadata = {
    pageTitle: 'Get Pro',
    pageDescription:
      'Members get access to 200k+ bite-sized ideas from 35 categories that cater to professional or personal goals & skills.',
    canonical: 'https://deepstash.com/get-pro',
    robotsTag: 'index',
    openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
  };
  return forTeamsPageMetadata;
};

/**
 * @link /explore
 */
export const getExplorePageMetadata = (): PageMetadata => ({
  pageTitle: 'Explore',
  pageDescription:
    'Explore the world’s best ideas: 200,000+ ideas on pretty much any topic. Created by the smartest people around & well-organized so you can explore at will.',
  canonical: 'https://deepstash.com/explore',
  robotsTag: 'index',
  openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
});

/**
 * @link /explore/sources/page/{sourceType}
 */
export const getExploreSourceTypePageMetadata = (
  sourceTypePlural: string,
): PageMetadata => ({
  pageTitle: `Explore ${sourceTypePlural}`,
  pageDescription: `Explore the world’s best ideas from ${sourceTypePlural}. Created by the smartest people around & well-organized so you can explore at will.`,
  canonical: `https://deepstash.com/explore/sources/page/${sourceTypePlural}`,
  robotsTag: 'index',
  openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
});

/**
 * @link /explore/sources/{pageType}/{sourceType}
 */
export const getExploreSourceTypeSeeAllPageMetadata = ({
  sourceTypePlural,
  pageTitle,
  pageType,
}: {
  sourceTypePlural: string;
  pageType: string;
  pageTitle: string;
}): PageMetadata => ({
  pageTitle: `Explore ${pageTitle}`,
  pageDescription: `Explore the world’s best ideas from ${sourceTypePlural}. Created by the smartest people around & well-organized so you can explore at will.`,
  canonical: `https://deepstash.com/explore/sources/${pageType}/${sourceTypePlural}`,
  robotsTag: 'index',
  openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
});

/**
 * @link /explore/daily_picks
 */
export const getExploreDailyPicksMetadata = (): PageMetadata => ({
  pageTitle: `Explore Daily Picks`,
  pageDescription: `Explore the best daily content from Deepstash, hand-picked by our curators.`,
  canonical: `https://deepstash.com/explore/daily_picks`,
  robotsTag: 'index',
  openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
});

/**
 * @link /explore/daily-picks/history/?type={pageType}
 */
export const getExploreDailyPicksHistoryMetadata = (
  pageTitle: string,
  pageType: string,
): PageMetadata => ({
  pageTitle: `Explore ${pageTitle}`,
  pageDescription: `Explore all the past daily picks from our curators.`,
  canonical: `https://deepstash.com/explore/daily_picks/history/?type=${pageType}`,
  robotsTag: 'index',
  openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
});

/**
 * @link /following
 * @return {PageMetadata} metadata
 */
export const getFollowingPageMetadata: PageMetadataGetter = () => {
  const addSourceMetadata: PageMetadata = {
    pageTitle: 'Following',
    pageDescription: '',
    canonical: '/following',
    robotsTag: 'noindex',
    openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
  };
  return addSourceMetadata;
};

/**
 * @link /recommended
 * @return {PageMetadata} metadata
 */
export const getRecommendedPageMetadata: PageMetadataGetter = () => {
  const addSourceMetadata: PageMetadata = {
    pageTitle: 'Recommended',
    pageDescription: '',
    canonical: '/recommended',
    robotsTag: 'noindex',
    openGraphTags: DEFAULT_PUBLIC_PAGE_OG_TAGS,
  };
  return addSourceMetadata;
};

/**
 * @link /redeem
 */
export const getRedeemPageMetadata: PageMetadataGetter = () => {
  const redeemPageMetadata: PageMetadata = {
    pageTitle: 'Redeem Code',
    pageDescription: '',
    canonical: '/redeem',
    robotsTag: 'noindex',
  };
  return redeemPageMetadata;
};

/**
 * @link /story/[id]/[slug], 301 redirects: /story/[id], /story/[id]/[wrong-slug]
 * @returns {PageMetadata} jsonld + opengraph metadata
 */
export const getStoryPageMetadata: PageMetadataGetter<{
  story: StoryApiResponse;
}> = ({ story }) => {
  const canonicalUrl = `https://deepstash.com/story/${story.id}/${slugify(
    story.title,
  )}`;

  const storyPageMetadata: PageMetadata = {
    pageTitle: story.title,
    pageDescription: story.intro,
    canonical: canonicalUrl,
    robotsTag: 'index, max-image-preview:large',
    openGraphTags: {
      title: story.title,
      description: story.intro,
      type: 'article',
      image: story.image ?? undefined,
    },
    twitterTags: {
      card: 'summary_large_image',
    },
  };
  return storyPageMetadata;
};

/**
 * @link /studio
 */
export const getStudioPageMetadata: PageMetadataGetter = () => {
  const studioPageMetadata: PageMetadata = {
    pageTitle: 'Deepstash Studio',
    pageDescription:
      'Unleash your creativity and spread ideas that make an impact.',
    canonical: '/studio',
    robotsTag: 'index',
  };
  return studioPageMetadata;
};
