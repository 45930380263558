import { Icon, StyleProps } from 'deepstash-ui';
import React from 'react';

export const ExportSvg: React.FC<StyleProps> = props => {
  return (
    <Icon width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M3.82356 11.9983H4.53255C7.5 11.9982 7 12 6.17927 11.9983L11.1249 11.9978L11.1238 10.9961L11.1194 10.9951V9.49388L11.1238 9.49488L11.1238 4.55505L9.23147 6.27776C8.93911 6.54403 8.48168 6.56816 8.1594 6.35022L8.06706 6.27758C7.7748 6.01122 7.74831 5.59448 7.98753 5.30086L8.06726 5.21674L11.3581 2.21962C11.6502 1.9536 12.1071 1.92922 12.4294 2.14663L12.5218 2.2191L15.819 5.21622C16.1409 5.50883 16.1415 5.98379 15.8203 6.27706C15.5283 6.54368 15.0709 6.56836 14.7483 6.35081L14.6559 6.27827L12.7705 4.56505L12.7716 11.9978L17.7074 11.9983C17.7074 11.9983 15.5 11.9983 19.3541 11.9983H20.1764C20.6884 11.9983 21.0763 12.4195 20.9872 12.8789L19.3391 21.3803C19.2696 21.7387 18.9278 22 18.5283 22H5.35592C4.95269 22 4.60879 21.7339 4.54345 21.3714L3.01108 12.87C2.92876 12.4133 3.31557 11.9983 3.82356 11.9983ZM4.79316 13.4986L6.0551 20.4997H17.8376L19.1949 13.4986L4.79316 13.4986Z"
        fill="#3B3F44"
      />
    </Icon>
  );
};
