import { BookApiResponse } from 'api/api.types';
import {
  AppStoreButton,
  color,
  DownloadSvg,
  Flex,
  HeadphonesSvg,
  Link,
  PrimaryHeading,
  PrimaryText,
  spacing,
  Text,
  typography,
  useColorMode,
  useIsMobileView,
  useDeviceDetect,
} from 'deepstash-ui';
import React from 'react';
import NextImage from 'src/components/image/NextImage';
import Footer from 'src/components/layout/components/Footer';
import FullWidthSectionWrapper from 'src/components/layout/components/FullWidthSectionWrapper';
import HeadMetadata from 'src/components/metadata/HeadMetadata';
import Navbar from 'src/components/navbar/Navbar';
import { ChipData, PageMetadata, Source } from 'types';
import HashtagChipCarousel from 'src/components/hashtag/HashtagChipCarousel';
import NextLink from 'src/components/navigation/NextLink';
import { Star } from 'theme/icons';
import { normalizeSource } from 'utils/normalizers/source';
import SourceCollapsed from 'src/components/source/source-collapsed/SourceCollapsed';
import CollectionSourceHighlightedIdeas from '../collection/old/CollectionSourceHighlightedIdeas';
import ReactHtmlParser from 'react-html-parser';
import { makeTransformHtmlParserBookPageDescription } from 'utils/html-utils';
import { GAP_SIZE_REM, slugify } from 'utils/global';

const INTRODUCTORY_TITLE =
  'Your one stop book summary, audiobook and book review spot for:';

// Use in case topics are not found for the book
const HASHTAGS_FALLBACK = [
  'psychology',
  'motivationandinspiration',
  'personaldevelopment',
  'books',
  'leadershipandmanagement',
  'business',
  'philosophy',
];

export const getRandomBookRatingById = (id: number) => {
  const RATINGS = ['4.3', '4.4', '4.5', '4.6', '4.7', '4.8', '4.9'];

  const modulo = id % RATINGS.length;
  return RATINGS[modulo];
};

interface MasterBookPageProps {
  book: BookApiResponse;
  pageMetadata: PageMetadata;
}

const MasterBookPage: React.FC<MasterBookPageProps> = ({
  book,
  pageMetadata,
}) => {
  const { colorMode } = useColorMode();
  const isMobileView = useIsMobileView();
  const { isIos } = useDeviceDetect();

  const [descriptionExpanded, setDescriptionExpanded] = React.useState(false);

  const bookDescriptionString = React.useMemo(() => {
    return ReactHtmlParser(book.description, {
      transform: makeTransformHtmlParserBookPageDescription(),
    });
  }, [book]);

  const bookRating = React.useMemo(() => {
    return getRandomBookRatingById(book.id);
  }, [book]);

  const hashtags = React.useMemo(() => {
    if (!book.top_posts || book.top_posts.length === 0) {
      return [];
    }

    const allHashtags: string[] = [];

    if (book.top_posts && book.top_posts.length > 0) {
      book.top_posts.forEach(articleApiResponse => {
        // each article
        if (articleApiResponse.blocks && articleApiResponse.blocks.length > 0) {
          const lowerCaseHashtags =
            articleApiResponse.blocks[0].hashtags?.map(value =>
              value.toLowerCase(),
            ) ?? [];
          allHashtags.push(...(lowerCaseHashtags ?? []));
        }
      });
    }

    if (allHashtags.length === 0) {
      return HASHTAGS_FALLBACK;
    }

    return [...new Set(allHashtags)].slice(0, 10);
  }, [book]);

  const hashtagsChipData: ChipData[] = React.useMemo(() => {
    return hashtags.map(item => {
      return { name: item };
    });
  }, [hashtags]);

  const publishersNameJoin = React.useMemo(() => {
    if (book.publishers === undefined || book.publishers.length === 0) {
      return '';
    }

    const allNames = book.publishers.map(publisher => publisher.name);
    return allNames.join(', ');
  }, [book]);

  const renderAuthorsSection = () => {
    if (book.publishers === undefined || book.publishers.length === 0) {
      return null;
    }

    const publishersTotal = book.publishers.length;
    return (
      <Flex flexDirection="row" mt="0.5rem" gridColumnGap={8}>
        <PrimaryText
          type="regular"
          color={color[colorMode].textSecondary}
          size="l"
        >
          {'by'}
        </PrimaryText>
        {book.publishers.map((publisher, index) => {
          const publisherSlug = slugify(publisher.name);

          return (
            <NextLink
              href={`/book/author/${publisher.id}/${publisherSlug}`}
              passHref
              key={`publisher-${index}`}
            >
              <Link _focus={undefined} _hover={undefined}>
                <PrimaryText
                  type="semiBold"
                  color={color[colorMode].text}
                  size="l"
                >
                  {publisher.name}
                  {/** Add commas between publishers */}
                  {index < publishersTotal - 1 && ','}
                </PrimaryText>
              </Link>
            </NextLink>
          );
        })}
      </Flex>
    );
  };

  const aboutThisBookSection = (
    <Flex flexDirection="column" mt="2rem">
      <Flex flexDirection="row" justifyContent="space-between">
        <PrimaryText
          type="semiBold"
          color={color[colorMode].textSecondary}
          size="l"
        >
          {`About ${book.title} Book`}
        </PrimaryText>
      </Flex>
      <PrimaryText
        size="m"
        color={color[colorMode].textSecondary}
        noOfLines={descriptionExpanded ? undefined : 3}
      >
        {bookDescriptionString}
      </PrimaryText>
      <PrimaryText
        _hover={{ cursor: 'pointer' }}
        color={color[colorMode].text}
        onClick={() => {
          setDescriptionExpanded(prevValue => !prevValue);
        }}
      >
        {descriptionExpanded ? 'See Less' : 'See More'}
      </PrimaryText>
    </Flex>
  );

  const alsoGrabSection = (
    <Flex flexDirection="column" mt="2rem">
      <PrimaryText
        type="semiBold"
        color={color[colorMode].textSecondary}
        size="l"
      >
        {`Also grab ${book.title} Audiobook, with the Deepstash App.`}
      </PrimaryText>
      {isMobileView ? (
        <AppStoreButton w={'100%'} store={isIos ? 'Apple' : 'GooglePlay'} />
      ) : (
        <Flex
          flexDirection="row"
          justifyContent={'flex-start'}
          gridColumnGap={16}
        >
          <AppStoreButton
            w={spacing.toRem(192)}
            h={spacing.toRem(48)}
            store="Apple"
          />
          <AppStoreButton
            w={spacing.toRem(192)}
            h={spacing.toRem(48)}
            store="GooglePlay"
          />
        </Flex>
      )}
    </Flex>
  );

  const dividerLine = (
    <Flex height={2} bgColor={color[colorMode].underground} width={'100%'} />
  );

  const topicsSection = (
    <>
      {dividerLine}
      <HashtagChipCarousel
        hashtags={hashtagsChipData}
        color={color[colorMode].text}
        numberOfChipsThreshold={isMobileView ? 1 : 10}
        renderItem={item => (
          <NextLink href={`/topic/${item.item.name}`} passHref>
            <Link _focus={undefined} _hover={undefined}>
              <PrimaryText size="m" mr={spacing.XXS.rem}>
                #{item.item.name}
              </PrimaryText>
            </Link>
          </NextLink>
        )}
      />
    </>
  );

  const statsSection = (
    <>
      {dividerLine}
      <Flex justifyContent="space-between">
        <Flex alignItems="center" gridRowGap={8} flexDirection="column">
          <Star width={48} height={48} />
          {/** Random values */}
          <Text
            color={color[colorMode].text}
            textAlign={{ base: 'center', md: 'unset' }}
            {...typography.primaryTextFonts.medium.regular}
          >{`${bookRating}/5 (${book.identifier.slice(-4)} reviews)`}</Text>
        </Flex>
        <Flex alignItems="center" gridRowGap={8} flexDirection="column">
          <HeadphonesSvg width={48} height={48} />
          <Text
            color={color[colorMode].text}
            textAlign={{ base: 'center', md: 'unset' }}
            {...typography.primaryTextFonts.medium.regular}
          >
            Audio and text
          </Text>
        </Flex>
        <Flex alignItems="center" gridRowGap={8} flexDirection="column">
          <DownloadSvg width={48} height={48} />
          <Text
            color={color[colorMode].text}
            textAlign={{ base: 'center', md: 'unset' }}
            {...typography.primaryTextFonts.medium.regular}
          >
            Download free PDF
          </Text>
        </Flex>
      </Flex>
      {dividerLine}
    </>
  );

  const renderSource = ({ source }: { source: Source }) => {
    const highlightedIdeas = source.ideas.slice(0, 3);
    return (
      <Flex flexDirection="column">
        <SourceCollapsed size="large" source={source} headingSize="h2" />
        <CollectionSourceHighlightedIdeas
          ideas={highlightedIdeas}
          source={source}
        />
      </Flex>
    );
  };

  const imageComponent = (
    <NextImage
      wrapperStyle={{
        width: 220,
        height: 320,
        cursor: 'default',
      }}
      imageUrl={book.image}
      alt={`${book.title} by ${publishersNameJoin}`}
      blurhash={book.image_blurhash}
      priority={true}
    />
  );

  return (
    <>
      <HeadMetadata pageMetadata={pageMetadata} />
      <FullWidthSectionWrapper>
        <Navbar />
      </FullWidthSectionWrapper>

      {/* Book page header: title, image, description */}
      <FullWidthSectionWrapper
        my={GAP_SIZE_REM}
        containerProps={{
          flexDirection: isMobileView ? 'column-reverse' : 'row',
        }}
      >
        <Flex flexDirection="column" flex={1}>
          <PrimaryText
            type="regular"
            color={color[colorMode].textSecondary}
            size="l"
          >
            {INTRODUCTORY_TITLE}
          </PrimaryText>
          <PrimaryHeading
            textSize="l"
            type="bold"
            size="h1"
            color={color[colorMode].text}
            mt={16}
          >
            {`${book.title} Summary`}
          </PrimaryHeading>
          {renderAuthorsSection()}
          {aboutThisBookSection}
          {alsoGrabSection}
        </Flex>
        <Flex justifyContent="center">{imageComponent}</Flex>
      </FullWidthSectionWrapper>

      {/* Topics & stats section */}
      <FullWidthSectionWrapper
        flexGrow={1}
        containerProps={{
          flexDirection: 'column',
        }}
      >
        {topicsSection}
        {statsSection}
      </FullWidthSectionWrapper>

      {/* Source list section */}
      <FullWidthSectionWrapper
        flexGrow={1}
        containerProps={{
          flexDirection: 'column',
        }}
        py={32}
      >
        {book.top_posts &&
          book.top_posts.length > 0 &&
          book.top_posts.map((articleApi, _index) => {
            const normalizedSource = normalizeSource(articleApi);
            return <>{renderSource({ source: normalizedSource })}</>;
          })}
      </FullWidthSectionWrapper>
      <Footer />
    </>
  );
};

export default MasterBookPage;
