import { getStripeManageSubscriptionLink } from 'api/Purchases';
import {
  Box,
  color,
  ExternalSvg,
  Flex,
  Grid,
  LockSvg,
  PhoneSvg,
  PrimaryText,
  spacing,
  Spinner,
  Text,
  useColorMode,
} from 'deepstash-ui';
import { useHandleNsi } from 'hooks/auth';
import useCurrentSubscription from 'hooks/purchases/useCurrentSubscription';
import useRouter from 'hooks/useRouter';
import usePaywall from 'providers/hooks/usePaywall';
import React, { useEffect, useState } from 'react';
import useAuth from 'src/providers/hooks/useAuth';
import useProfile from 'src/providers/hooks/useProfile';
import { typography } from 'theme/typography';
import { RequestStatus } from 'types';
import { PaywallAnalyticsLocation, UserSubscriptionType } from 'types/enums';
import pageStrings from 'utils/strings/pageStrings';
import SettingsCard from './SettingsCard';
import ExportLibraryButton from 'src/components/buttons/ExportLibraryButton';

export enum SettingsType {
  PersonalInformation = 'personal-information',
  Preferences = 'preferences',
  Emails = 'email-subscriptions',
}

const FAQ_URL =
  'https://www.notion.so/deepstash/Help-Center-e3fdf92e3ec64ebea90a1c0c56ebf3bc#fde9bb9c5bb44f97b6c85575405721d3';

const settingsList = [
  {
    title: 'Personal Information',
    description: 'Details about your name, profile & email.',
    value: SettingsType.PersonalInformation,
  },
  {
    title: 'Preferences',
    description:
      'Customize your experience with display options or a custom reading goal. ',
    value: SettingsType.Preferences,
  },
  {
    title: 'Emails',
    description: 'Choose which emails you would like to receive.',
    value: SettingsType.Emails,
  },
];

const SettingsPage: React.FunctionComponent = () => {
  useHandleNsi({ onNsiEnterAction: 'redirect' });

  const { colorMode } = useColorMode();
  const router = useRouter();
  const { isLoggedIn } = useAuth();
  const { currentSubscription, fetchCurrentSubscription } =
    useCurrentSubscription();
  const { profile } = useProfile();

  const [stripeCustomerPortal, setStripeCustomerPortal] = useState<{
    reqStatus: RequestStatus;
    link?: string;
  }>();

  useEffect(() => {
    // make sure if the user logs in, we update the subscription
    fetchCurrentSubscription();
  }, [isLoggedIn]);

  const manageOnWeb = currentSubscription?.initialPaymentLocation === 'web';

  const openFAQPage = () => {
    window.open(FAQ_URL, '_blank');
  };

  const openStripePortal = () => {
    // make the api call only once
    if (stripeCustomerPortal?.link) {
      window.open(stripeCustomerPortal.link, '_blank');
      return;
    }
    // make sure we only make one api call when user is clicking rapidly
    if (stripeCustomerPortal?.reqStatus === 'loading') {
      return;
    }

    setStripeCustomerPortal({ reqStatus: 'loading' });

    getStripeManageSubscriptionLink()
      .then(response => {
        setStripeCustomerPortal({
          reqStatus: 'success',
          link: response.session_url,
        });
        window.open(response.session_url, '_blank');
      })
      .catch(() => {
        setStripeCustomerPortal({ reqStatus: 'failure' });
      });
  };

  const renderManageSubscriptionButton = () => (
    <Flex
      onClick={openStripePortal}
      paddingX="0.8rem"
      paddingY="0.7rem"
      width="auto"
      cursor="pointer"
      alignItems="center"
    >
      <LockSvg color={color[colorMode].primary.default} />
      <PrimaryText size="m" type="bold" marginX="0.4rem">
        Manage subscription
      </PrimaryText>
      {stripeCustomerPortal?.reqStatus === 'failure' && (
        <PrimaryText color={color.failure.default} marginX="0.3rem">
          Try again
        </PrimaryText>
      )}
      {stripeCustomerPortal?.reqStatus === 'loading' ? (
        <Flex marginTop={'0.3rem'}>
          <Spinner width={14} height={14} />
        </Flex>
      ) : (
        <ExternalSvg
          color={
            stripeCustomerPortal?.reqStatus === 'failure'
              ? color.failure.default
              : color[colorMode].primary.default
          }
          boxSize={16}
        />
      )}
    </Flex>
  );

  const renderManageOnDeviceButton = () => (
    <Flex
      onClick={openFAQPage}
      paddingX="0.4rem"
      paddingY="0.7rem"
      width="auto"
      cursor="pointer"
      alignItems="center"
    >
      <PhoneSvg color={color[colorMode].text} />
      <PrimaryText size="s" color={color[colorMode].text}>
        {pageStrings.settings.manageOnDevice}
      </PrimaryText>
      <PrimaryText color={color[colorMode].primary.default} marginX="0.3rem">
        Learn how
      </PrimaryText>
      <ExternalSvg boxSize={16} />
    </Flex>
  );

  const { onPaywallModalOpen } = usePaywall();

  const renderTryDeepstashPro = () => {
    return (
      <Flex
        onClick={() => {
          onPaywallModalOpen({
            location: PaywallAnalyticsLocation.SettingsPage,
          });
        }}
        cursor="pointer"
        width="auto"
        alignItems="center"
      >
        <LockSvg color={color[colorMode].pro.primary} />
        <PrimaryText
          size="m"
          type="bold"
          color={color[colorMode].pro.primary}
          marginLeft={spacing.XXS.rem}
        >
          {pageStrings.settings.tryDeepstashPRO}
        </PrimaryText>
      </Flex>
    );
  };

  const renderSubscriptionButton = manageOnWeb
    ? renderManageSubscriptionButton
    : renderManageOnDeviceButton;

  const renderSubscriptionCTA = () => {
    if (
      profile?.subscriptionType === UserSubscriptionType.Premium &&
      !currentSubscription
    )
      return null;
    return currentSubscription &&
      profile?.subscriptionType === UserSubscriptionType.Premium
      ? renderSubscriptionButton()
      : renderTryDeepstashPro();
  };

  return (
    <Box width="100%" minH="100vh">
      <Flex
        mb="1rem"
        ml={{ base: '1rem', lg: 0 }}
        flex={1}
        justifyContent="space-between"
        alignItems="center"
        // When not rendering anything (i.e. while loading)
        // We need to minimize CLS, so we set the minH
        minH={spacing.toRem(50)}
      >
        <Text
          {...typography.heading.medium.primary.bold}
          color={color[colorMode].text}
        >
          {pageStrings.settings.title}
        </Text>
        {renderSubscriptionCTA()}
      </Flex>
      <Grid
        gridTemplateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }}
        gridGap="1rem"
        mx={{ base: '0.5rem', lg: 'unset' }}
      >
        {settingsList.map(element => (
          <SettingsCard
            key={`settingsCard$${element.title}`}
            selectTab={() => {
              router.push(`/settings/${element.value}`, undefined, {
                shallow: true,
              });
            }}
            {...element}
          />
        ))}
        <ExportLibraryButton />
      </Grid>
    </Box>
  );
};

export default SettingsPage;
