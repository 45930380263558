import {
  Flex,
  typography,
  color,
  spacing,
  useColorMode,
  PrimaryButton,
  Text,
} from 'deepstash-ui';
import index from 'pages/settings';
import IdeaCard from 'src/components/idea-card/IdeaCard';
import { BORDER_RADIUS_M } from 'theme/size';
import { getRemWidthFromColumns, GAP_SIZE_REM, slugify } from 'utils/global';
import React, { useCallback } from 'react';
import { Idea, Source } from 'types/models';
import { createGradient } from 'utils/constants';
import actionStrings from 'utils/strings/actionStrings';
import useAuth from 'providers/hooks/useAuth';
import useNsi from 'providers/hooks/useNsi';
import { useRouter } from 'next/router';

interface CollectionSourceHighlightedIdeasProps {
  ideas: Idea[];
  source: Source;
}

const CollectionSourceHighlightedIdeas: React.FC<
  CollectionSourceHighlightedIdeasProps
> = ({ ideas, source }) => {
  const { colorMode } = useColorMode();

  const { nsiDispatch } = useNsi();
  const { isLoggedIn } = useAuth();
  const router = useRouter();

  const onClickCtaHandler = useCallback(() => {
    if (!isLoggedIn) {
      nsiDispatch({
        type: 'openAuth',
        payload: {
          type: 'sign-up',
        },
      });
    } else {
      router.push(`/article/${source.id}/${slugify(source.title)}`);
    }
  }, [isLoggedIn, source.id]);

  if (!ideas || ideas.length === 0) return null;

  return (
    <Flex
      w={{
        base: 'auto',
        md: getRemWidthFromColumns(8),
        lg: getRemWidthFromColumns(12),
      }}
      key={`answer-${index}`}
      {...typography.primaryTextFonts.large.regular}
      color={color[colorMode].text}
      mt={GAP_SIZE_REM}
      gridGap={GAP_SIZE_REM}
      padding={{ base: 0, md: GAP_SIZE_REM }}
      height="auto"
      maxHeight={{ base: 'auto', md: spacing.toRem(400) }}
      position="relative"
      overflow="hidden"
      flexDirection={{ base: 'column', md: 'row' }}
      backgroundColor={{ base: 'transparent', md: color[colorMode].surface }}
      alignItems="flex-start"
      borderRadius={BORDER_RADIUS_M}
    >
      {ideas.map((idea, index) => (
        <IdeaCard key={index} ideaData={{ idea, source }} />
      ))}
      <Flex
        position="absolute"
        width="100%"
        height="66%"
        background={createGradient(color[colorMode].surface, 270)}
        zIndex={1}
        bottom={0}
        left={0}
        alignItems="flex-end"
        padding={GAP_SIZE_REM}
        justifyContent="center"
      >
        <PrimaryButton
          size="lg"
          onClick={onClickCtaHandler}
          _hover={{
            backgroundColor:
              color[colorMode === 'dark' ? 'light' : 'dark'].underground,
          }}
          width={{ base: '100%', md: getRemWidthFromColumns(3) }}
          height="auto"
          transition="background-color 0.3s ease-out"
          backgroundColor={color[colorMode].text}
          color={color[colorMode].textInverted}
          {...typography.primaryTextFonts.large.semiBold}
          align="center"
        >
          <Text>
            {isLoggedIn
              ? actionStrings.readIdeas
              : actionStrings.signUpToReadForFree}
          </Text>
        </PrimaryButton>
      </Flex>
    </Flex>
  );
};

export default CollectionSourceHighlightedIdeas;
