import React from 'react';
import LayoutAdapter from 'src/components/layout/LayoutAdapter';
import SettingsPage from 'src/page-components/settings/settings-page/SettingsPage';
import { getSettingsPageMetadata } from 'utils/metadata.utils';

const index: React.FC = ({}) => {
  return (
    <LayoutAdapter pageMetadata={getSettingsPageMetadata()}>
      <SettingsPage />
    </LayoutAdapter>
  );
};

export default index;
